//The files in this directory, including app.js, are copied in core/js-compiled folder and compiled toghether with the other, 
//In this way you are free to import all the packages listed in package.json and also core js files to compiled (like defaultmenu.js)
//Remember that you are in the same core/js-compiled directory, so the import must be without "../../" reference

// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
import "phoenix_html"
// Establish Phoenix Socket and LiveView configuration.
import {Socket} from "phoenix"
import {LiveSocket} from "phoenix_live_view"
import Alpine from 'alpinejs';
// Alpine Directives
import usePopper from './usePopper';
// Alpine Directives
import tooltip from './tooltip';
import swal from 'sweetalert';
import flatpickr from 'flatpickr'; // @see https://flatpickr.js.org/

import { LiveMenu } from "./defaultmenu"
import ColumnsDropdownHook from './hooks/table_dropdown_hook';
import CsvExportHook from './hooks/csv_export_hook';
import TableHeightHook from './hooks/table_height_hooks';
import deletePreFilter from './hooks/popup_modals/delete_pre_filter';
import PannelResizerHook from './hooks/panel_resize_hook';

// AlpineJS Plugins
import persist from '@alpinejs/persist'; // @see https://alpinejs.dev/plugins/persist
import collapse from '@alpinejs/collapse'; // @see https://alpinejs.dev/plugins/collapse
import intersect from '@alpinejs/intersect'; // @see https://alpinejs.dev/plugins/intersect

let liveMenu = undefined;

let Hooks = {}

Hooks.ColumnsDropdown = ColumnsDropdownHook;
Hooks.CsvExport = CsvExportHook;
Hooks.TableHeight = TableHeightHook;
Hooks.deletePreFilter = deletePreFilter;
Hooks.PannelResizer = PannelResizerHook;


Hooks.LiveMenu = {
	mounted() {
		liveMenu = new LiveMenu();
		if (liveMenu.closed) {
			liveMenu.toggleSidemenu();
		}
	},
	updated() {
		liveMenu = new LiveMenu();
		if (liveMenu.closed) {
			liveMenu.toggleSidemenu();
		}
	},
};

window.Alpine = Alpine;
window.flatpickr = flatpickr;
Alpine.plugin(persist);
Alpine.plugin(collapse);
Alpine.plugin(intersect);

Alpine.data('usePopper', usePopper);

Alpine.directive('tooltip', tooltip);

Alpine.start();

let csrfToken = document
	.querySelector("meta[name='csrf-token']")
	.getAttribute('content');
let liveSocket = new LiveSocket('/live', Socket, {
	dom: {
		onBeforeElUpdated(from, to) {
			if (from._x_dataStack) {
				window.Alpine.clone(from, to);
			}
		},
	},
	hooks: Hooks,
	//longPollFallbackMs: 5000,
	params: { _csrf_token: csrfToken },
});

liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)  // enabled for duration of browser session
// >> liveSocket.disableLatencySim()
window.liveSocket = liveSocket


function hideLoader() {
	const loader = document.getElementById('loader');
	loader.classList.add('!hidden');
}
window.addEventListener('load', hideLoader);