import Swal from 'sweetalert2';

const deletePreFilter = {
	mounted() {
	  console.log("deletePreFilter hook mounted for element:", this.el);
	  const action_target = this.el.getAttribute('action_target') || "self";
	  const action_event = this.el.getAttribute('action_event');
	  const action_message = this.el.getAttribute('action_message');
	  const main_title = this.el.getAttribute('main_title') || "Confirm Delete";
	  const icon_type = this.el.getAttribute('icon_type') || "info";
	  const button_text = this.el.getAttribute('button_text') || "Delete";
	  const cancel_button_text = this.el.getAttribute('cancel_button_text') || "Cancel";
  
	  this.el.addEventListener(action_event, (event) => {
		event.preventDefault();
		console.log("deletePreFilter event triggered for:", action_event);
  
		Swal.fire({
		  title: main_title,
		  text: action_message || "Are you sure you want to proceed?",
		  icon: icon_type,
		  allowOutsideClick: false,
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: button_text,
		  cancelButtonText: cancel_button_text,
		}).then((result) => {
		  if (result.isConfirmed) {
			console.log("Confirmed action for asset:", this.el.getAttribute('asset_id'));
			let asset_id = this.el.getAttribute('asset_id');
			this.pushEventTo(action_target === "self" ? this.el : action_target, action_event, { asset_id: asset_id });
		  }
		});
	  });
	},
  };

  export default deletePreFilter;